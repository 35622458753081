var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"section",class:'bg-'+_vm.data.SectionBackgroundColor,attrs:{"id":"section-project-slider"}},[(_vm.data.BackgroundImage)?_c('div',{staticClass:"bg"},[_c('ResponsiveImageStrapi',{attrs:{"src":_vm.data.BackgroundImage}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container container-pad"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-24 col-md-24"},[_c('div',{staticClass:"content center"},[_c('div',{staticClass:"section-title align-center"},[_c('h2',[_vm._v(_vm._s(_vm.data.Headline))])])])])])]),_vm._v(" "),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content"},[_c('client-only',[(_vm.projects)?_c('vue-glide',{attrs:{"options":{
					type: 'slider',
					//focusAt: '0',
					perView: 3,
					startAt: 0,
					bound: true,
					gap: 20,
					autoplay: false,
					hoverpause: true,
					rewind: false,
					touchDistance: 100000,
					dragDistance: 100000,
					breakpoints: {
						800: {
							perView: 2,
						},
					},
				}}},[_vm._l((_vm.projects_recent),function(project,index){return [_c('vue-glide-slide',{key:index},[_c('div',{ref:"project",refInFor:true,staticClass:"project"},[(project.FeaturedImage)?_c('div',{staticClass:"image"},[_c('ResponsiveImageStrapi',{attrs:{"src":project.FeaturedImage,"size":"mobile","alt":project.title}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content"},[(project.title)?_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(project.title)+"\n\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(project.DescriptionShort)?_c('div',{staticClass:"copy"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(project.DescriptionShort)+"\n\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(project.categories)?_c('div',{staticClass:"categories"},[_c('ul',[_vm._l((project.categories),function(category,index2){return [_c('li',{key:index2,staticClass:"font-size-1-3"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(category.title)+"\n\t\t\t\t\t\t\t\t\t\t\t")])]})],2)]):_vm._e(),_vm._v(" "),(project.slug)?_c('nuxt-link',{staticClass:"cta btn",attrs:{"to":{ path: '/projects/' + project.slug }}},[_vm._v("VIEW PROJECT")]):_vm._e()],1)])])]}),_vm._v(" "),_c('template',{slot:"control"},[_c('button',{staticClass:"glide__arrow glide__arrow--left",attrs:{"data-glide-dir":"<","name":"previous"}},[_c('span',[_c('em',[_vm._v("Previous")])])]),_vm._v(" "),_c('button',{staticClass:"glide__arrow glide__arrow--right",attrs:{"data-glide-dir":">","name":"next"}},[_c('span',[_c('em',[_vm._v("Next")])])])]),_vm._v(" "),_c('div',{staticClass:"glide__bullets",attrs:{"slot":"control"},slot:"control"},[_c('div',{attrs:{"data-glide-el":"controls[nav]"}},[_vm._l((_vm.projects),function(slide,index3){return [_c('button',{key:index3,staticClass:"glide__bullet",attrs:{"data-glide-dir":'='+index3,"name":'slide '+index3}},[_c('span',[_vm._v("Slide "+_vm._s(index3))])])]})],2)])],2):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }