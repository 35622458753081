//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gsap from 'gsap'
import ScrollTrigger from '../../node_modules/gsap/dist/ScrollTrigger'
import ResponsiveImageStrapi from '@/components/modules/ResponsiveImageStrapi'

export default {
	props: {
		data: {
			type: Object,
			default: null,
		},
	},
	components: {
		ResponsiveImageStrapi,
	},
	computed: {
		projects() {
			return this.$store.getters.projects
		},
		projectsCat() {
			const categories = this.getArray(this.data.categories);
			if (categories.length){
				const final = Object.entries(this.projects).filter((project) => { 
					return categories.some(value => project[1].categories.map( (item) => item.id).includes(value))
				});
				return Object.fromEntries(final);
			} else {
				return this.projects;
			}
		},
		projects_recent() {
			const values = Object.values(this.projectsCat)
			return values.sort((a, b) => b.Year - a.Year)
		},
	},
	async asyncData({ params, error, store }) {
		try {
			await store.dispatch('getProjectsAll')
		} catch (e) {
			error({
				statusCode: e.statusCode,
				message: e.message,
			})
		}
	},
	mounted() {
		this.$nextTick(this.Intro)
	},
	methods: {
		isIncategory(cats) {
			const categories = this.getArray(this.data.categories);
			const projectcat = this.getArray(cats);
			return categories.some(value => projectcat.includes(value))
		},
		getArray(array) {
			return array.map( (item) => item.id);
		},
		Intro() {
			setTimeout(() => {
				gsap.registerPlugin(ScrollTrigger)
				const page = this.$refs.section.closest('.page')
				const tl = gsap.timeline({
					scrollTrigger: {
						scroller: page,
						trigger: this.$refs.section,
						start: "top center",
						// markers: true
					}
				});
				tl.fromTo(this.$refs.project, {
					autoAlpha: 0,
					y: 200
				}, {
					duration: 1.5,
					autoAlpha: 1,
					y: 0, 
					stagger:0.1
				});
			}, 0)
		},
	},
}
