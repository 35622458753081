//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: {
		src: {
			type: Object,
			default: null,
		},
		alt: {
			type: String,
			default: null,
		},
		classname: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: null,
		},
		fit: {
			type: String,
			default: null,
		},
		lazy: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			observer: null,
			imageloaded: false,
		}
	},
	computed: {
		getSizes() {
			let keys = []
			switch (this.size) {
				case 'base64':
					keys = ['base64']
					break
				case 'thumbnail':
					keys = ['thumbnail']
					break
				case 'small':
					keys = ['small']
					break
				case 'mobile':
					keys = ['mobile', 'small']
					break
				case 'laptop':
					keys = ['laptop', 'mobile', 'small']
					break
				case 'desktop':
					keys = ['desktop', 'laptop', 'mobile', 'small']
					break
				default:
					keys = ['hd', 'desktop', 'laptop', 'mobile', 'small']
			}
			return keys
		},
		getFormat() {
			return this.src.mime
		},
		getPlaceholder() {
			let base64 = ''
			if (this.src.formats) {
				base64 = this.src.formats.base64.url
			}
			return base64
		},
		getBase64() {
			let base64 = ''
			if (this.src.formats) {
				base64 = this.src.formats.base64.url
			}
			return base64
		},
		getSrc() {
			let path = this.src.url
			if (this.size) {
				if (this.src.formats[this.size]) {
					path = this.src.formats[this.size].url
				}
			}
			return process.env.assetUrl + path
		},
		getSrcSet() {
			let srcset = ''
			if (this.src.formats) {
				for (const [key, value] of Object.entries(this.src.formats)) {
					if (value.url) {
						if (this.getSizes.includes(key)) {
							const size = value.width
							const url = process.env.assetUrl + value.url
							srcset += `${url} ${size}w, `
						}
					}
				}
			}
			return srcset
		},
		getSrcSetWebp() {
			let srcset = ''
			if (this.src.formats) {
				for (const [key, value] of Object.entries(this.src.formats)) {
					if (value.webp) {
						if (this.getSizes.includes(key)) {
							const size = value.width
							const url = process.env.assetUrl + value.webp.url
							srcset += `${url} ${size}w, `
						}
					}
				}
			}
			return srcset
		},
		getWidth() {
			let width = this.src.width
			if (this.size) {
				if (this.src.formats[this.size]) {
					width = this.src.formats[this.size].width
				}
			}
			return width
		},
		getHeight() {
			let height = this.src.height
			if (this.size) {
				if (this.src.formats[this.size]) {
					height = this.src.formats[this.size].height
				}
			}
			return height
		},
		getFit() {
			return this.fit
		},
		getAspectRatio() {
			if (!this.getWidth || !this.getHeight) return 100
			return (this.getHeight / this.getWidth) * 100
		},
		getAlt() {
			let alt = this.src.alternativeText
			if (this.alt) {
				alt = this.alt
			}
			return alt
		},
	},
	mounted() {
		if ('isIntersecting' in window.IntersectionObserverEntry.prototype) {
			const options = {
				root: document.querySelector('.page:last-of-type'),
				rootMargin: '0px',
				threshold: 0.005,
			}
			this.observer = new IntersectionObserver(this.updateImg, options)
			this.observer.observe(this.$refs.image)
		} else {
			// fallback for IE / Opera / Baidu
			this.setFallback()
		}
		if (this.lazy === false) {
			this.setFallback()
		}
	},
	beforeDestroy() {
		// console.log(`IMAGE -beforeDestroy`)
		if (this.observer) {
			this.unobserve()
			this.observer = null
		}
	},
	methods: {
		getImg(img) {
			const images = require.context('~/assets/', false)
			return images('./' + img)
		},
		imageLoad(event) {
			// this.imageloaded = true
		},
		unobserve() {
			this.observer.unobserve(this.$refs.image)
		},
		setFallbackImageUrl(event) {
			// console.log('Image failed to load, setting fallback.')
			// event.target.src = require('~/assets/columbia_thumb.jpg')
		},
		setFallback() {
			console.log('Image failed to load, setting fallback.')
			const src = this.$refs.image.getAttribute('data-src')
			if (src) {
				this.$refs.image.src = src
				this.$refs.image.removeAttribute('data-src')
			}
			if (this.$refs.srcset) {
				const srcset = this.$refs.srcset.getAttribute('data-srcset')
				if (srcset) {
					this.$refs.srcset.srcset = srcset
					this.$refs.srcset.removeAttribute('data-srcset')
				}
			}
			if (this.$refs.srcsetwebp) {
				const srcsetwebp = this.$refs.srcsetwebp.getAttribute('data-srcset')
				if (srcsetwebp) {
					this.$refs.srcsetwebp.srcset = srcsetwebp
					this.$refs.srcsetwebp.removeAttribute('data-srcset')
				}
			}
			this.imageloaded = true
			this.unobserve()
		},
		updateImg(observerEntry) {
			// console.log("observerEntry: ", observerEntry[0])
			if (this.imageloaded) {
				return
			}
			// console.log("observerEntry: ", observerEntry[0].intersectionRatio)
			if (observerEntry[0].intersectionRatio > 0) {
				// console.log("observerEntry: load image")
				const src = this.$refs.image.getAttribute('data-src')
				if (src) {
					this.$refs.image.src = src
					this.$refs.image.removeAttribute('data-src')
				}
				if (this.$refs.srcset) {
					const srcset = this.$refs.srcset.getAttribute('data-srcset')
					if (srcset) {
						this.$refs.srcset.srcset = srcset
						this.$refs.srcset.removeAttribute('data-srcset')
					}
				}
				if (this.$refs.srcsetwebp) {
					const srcsetwebp = this.$refs.srcsetwebp.getAttribute('data-srcset')
					if (srcsetwebp) {
						this.$refs.srcsetwebp.srcset = srcsetwebp
						this.$refs.srcsetwebp.removeAttribute('data-srcset')
					}
				}
				this.imageloaded = true
				this.unobserve()
			}
		},
	},
}
